import axios from 'axios';

const baseURL = 'https://discount-codes-service-2-sguhogpuha-uc.a.run.app';

// Status check
export const statusCheck = () => {
	return axios.get(`${baseURL}/`);
};

// Account Management
export const login = (userData) => {
	return axios.post(`${baseURL}/verify_login`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

// Token Management
export const checkToken = (userData) => {
	return axios.post(`${baseURL}/check_token`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

// Redemption Management
export const redeemCode = (userData) => {
	return axios.post(`${baseURL}/check_redeem_code`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};

export const undoRedeem = (userData) => {
	return axios.post(`${baseURL}/undo_redeem_code`, userData, {
		headers: { 'Content-Type': 'application/json' }
	});
};